@import "../../../styles/variable.module.scss";
.top_collections {
  padding-bottom: 40px;
  @include large_device {
    padding-bottom: 35px;
  }
  .seeAll {
    margin-top: 32px;
    a {
      width: 250px;
    }
  }
  .customBTab {
    @include medium_device {
      order: 3;
      width: 100%;
      margin-top: 32px;
    }
  }
  h3 {
    @include medium_device {
      font-size: 32px;
    }
    @include mobile_device {
      font-size: 24px;
    }
  }
  .selectMenu {
    width: 256px;
    @include mobile_device {
      width: 100%;
      margin-top: 20px;
    }
  }
  .collection_list {
    margin-top: 16px;
    .collection_list_wrap {
      padding: 15px;
      cursor: pointer;
      border-radius: 12px;
      margin-top: 24px;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      @include medium_device {
        margin-top: 0;
        border-bottom: 1px solid var(--border-color);
        padding: 16px 8px;
        &:last-child {
          margin-top: 0;
          border-bottom: none;
        }
      }
      &:hover {
        background: var(--light-bg);
      }
      .count {
        @include fontWeight(font-weight, 500, 700);
        font-size: 14px;
        line-height: 24px;
        color: var(--desc-font);
        margin-right: 15px;
        width: 20px;
      }
      .card__avatar {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        -webkit-box-shadow: 0 0 4px 0px rgba(15, 15, 15, 0.2);
        box-shadow: 0 0 4px 0px rgba(15, 15, 15, 0.2);
        border-radius: 50px;
        position: relative;
        img {
          border-radius: 50px;
          object-fit: cover;
        }
        .img {
          width: 50px;
          height: 50px;
        }
        .done {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 16px;
          height: 16px
        }
      }
      .card_body {
        .name {
          @include fontWeight(font-weight, 600, 700);
          font-size: 14px;
          line-height: 24px;
          color: var(--desc-font);
          margin-bottom: 4px;
        }
        .content {
          line-height: 20px;
          @include fontWeight(font-weight, 600, 700);
          font-size: 12px;
          color: $customFont;
          span {
            & + span {
              width: 16px !important;
              height: 16px !important;
            }
          }
          .price_lbl {
            font-size: 12px;
            color: $customFont;
            margin-right: 6px;
            font-weight: 400;
          }
          .rate {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
[dir="rtl"] {
  .top_collections {
    .collection_list {
      .collection_list_wrap {
        .count {
          margin-left: 15px;
          margin-right: 0;
        }
        .card__avatar {
          margin-right: 0;
          margin-left: 15px;
          .done {
            left: 0;
            right: auto;
          }
        }
        .card_body {
          .content {
            .price_lbl {
              margin-right: 0;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
}
.clnImg
{
  height: 50px;
  width: 50px;
}
[data-theme=dark] {
  .top_collections {
    .collection_list {
      .collection_list_wrap {
        .card__avatar {
          box-shadow: 0 0 4px 0 $customFont;
        }
      }
    }
  }
}